import { PUBLIC_URL, PUBLIC_SENTRY_ENVIRONMENT } from '$env/static/public';
import { handleErrorWithSentry, Replay } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
  dsn: 'https://3b078319f9afe56ab38588937c6a9357@o4506351488204800.ingest.sentry.io/4506351489187840',
  tracesSampleRate: 1.0,
  environment: PUBLIC_SENTRY_ENVIRONMENT || 'development',
  enabled: (PUBLIC_SENTRY_ENVIRONMENT || 'development') !== 'development',

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.001,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0.8,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [new Replay()],

  // Tunnel to avoid adblockers
  tunnel: PUBLIC_URL + '/api/bugs',
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = () => handleErrorWithSentry();
